import React, { useEffect } from "react";
import Countdown from "react-countdown";


const ComingSoon = () => {
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);
  const Completionist = () => <span>Mega!</span>;
  

  // Renderer callback with condition
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      // Render a complete state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <ul className="p-0 m-0">
          <li>
            <span id="days">{days}</span>Gün
          </li>
          <li>
            <span id="hours">{hours}</span>Saat
          </li>
          <li>
            <span id="minutes">{minutes}</span>Dakika
          </li>
          <li>
            <span id="seconds">{seconds}</span>Saniye
          </li>
        </ul>
      );
    }
  };

  var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var countdown = new Date('2023-09-04 09:00:00').getTime();

  return (
    <div>
      {/*home section*/}
      <section className="authentication-form coming-soon" style={{backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/aut-bg.jpg)`}}>
        <div className="innerpage-decor">
          <div className="innerpage-circle1">
            <img src={`${process.env.PUBLIC_URL}/assets/images/Testimonial2.png`} alt="" />
          </div>
          <div className="innerpage-circle2">
            <img src={`${process.env.PUBLIC_URL}/assets/images/Testimonial1.png`} alt="" />
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 offset-xl-3">
              <div className="fadeInLeft-land-caption text-left">
                
                  <div className="d-flex text-center d-align-center">
                    <div className=" text-center">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/logo512.png`} alt="appstore" className="" />
                    </div>

                    <div className="text-center">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/logoImage.png`} alt="appstore" className="" />
                    </div>
                   
                  </div>

                  <h2 className="top-margin text-center ">
                BAŞVURULAR TAMAMLANMIŞ OLUP İLGİNİZ İÇİN TEŞEKKÜR EDERİZ
                </h2>  
                
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*home section end*/}

    

      {/*Footer Section start*/}
      <footer className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="copyright-section">
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/*Footer Section End*/}
    </div>
  );
};

export default ComingSoon;
